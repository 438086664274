<template>
<div class="scope">
    <b-modal v-model="showRegisModal" :hide-header="true" :hide-footer="true">
        <Register />
    </b-modal>
    <b-modal v-model="showForgetModal" :hide-header="true" :hide-footer="true">
        <ForgetPass />
    </b-modal>
    <img src="./../../assets/logo_novelrealm.png" width="80px" />
    <div>เข้าสู่ระบบ</div>
    <div class="input">
        <label for="username">ชื่อผู้ใช้งาน</label>
        <b-form-input id="username" v-model="username" @keyup.enter="login(username, password)" placeholder="ชื่อผู้ใช้งาน"></b-form-input>
    </div>
    <div class="input">
        <label for="password">รหัสผ่าน</label>
        <b-form-input type="password" id="password" v-model="password" @keyup.enter="login(username, password)" placeholder="รหัสผ่าน"></b-form-input>
    </div>
    <b-container class="bv-example-row option">
        <b-row>
            <b-col class="text-left">
                <b-form-checkbox value="true" v-model="autoLogin">เข้าสู่ระบบอัตโนมัติ</b-form-checkbox>
            </b-col>
            <b-col class="text-right forgot" @click="showForgetModal = true">
                <span class="forgot">ลืมรหัสผ่าน?</span>
            </b-col>
        </b-row>
    </b-container>
    <div class="searching">
        <b-button class="login" @click="login(username, password)">
            <b-spinner v-if="loadingLogin" small label="Small Spinner" class="mr-2"></b-spinner>เข้าสู่ระบบ
        </b-button>
    </div>
    <div class="searching">
        <b-button class="loginWithFb" v-if='linkToFb==""' :href="linkToFb"><b-spinner small label="Small Spinner" class="m-auto"></b-spinner></b-button>
        <b-button class="loginWithFb" v-else :href="linkToFb"><img src="./../../assets/facebook1.png" width="20" class="mr-1" />ดำเนินการต่อด้วย Facebook</b-button>
        <vue-apple-signin class="mb-1"></vue-apple-signin>
    </div>
    <div class="asking">ยังไม่ได้เป็นสมาชิก Novelrealm ใช่ไหม ? <span class="signUp"  @click="showRegisModal = true">ลงทะเบียน</span></div>
</div>
</template>

<script>
import axios from 'axios'
import Register from "./Register"
import ForgetPass from "./ForgetPass"
import {
    mapActions
} from 'vuex'
import VueAppleSignin from 'vue-apple-signin';
import Vue from 'vue'

const isLogin = localStorage.getItem('UserInfo') != null
if(isLogin == false){
    Vue.use(VueAppleSignin, {
        clientId: 'com.phoenigon.novelrealm.signin',
        scope: 'name email',
        redirectURI: 'https://api2.novelrealm.com/module/apple&submodule=auth',
        state: 'login',
    });
}
export default {
    data() {
        return {
            showForgetModal: false,
            showRegisModal: false,
            linkToFb: "",
            loadingLogin: false
        }
    },
    components: {
        ForgetPass,
        Register
    },
    async mounted() {
        const {
            data
        } = await axios.get('https://api2.novelrealm.com/module/facebook&submodule=generate')
        this.linkToFb = data.redirect_url
        //console.log(this.linkToFb)
        // if(localStorage.getItem('AutoLogin')){
        //     this.username = localStorage.getItem('Username')
        //     this.password = localStorage.getItem('Password')
        // }
    },
    methods: {
        ...mapActions(['SET_LOGIN_STATUS', 'SET_USER_INFO']),
        async login(username, password) {
            this.loadingLogin = true
            //console.log(username + " : " + password)
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/login", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.status == 200) {
                            if (this.autoLogin) {
                                localStorage.setItem('AutoLogin', true);
                            } else {
                                localStorage.setItem('AutoLogin', false);
                            }
                            this.SET_USER_INFO({
                                auth_token: res.data.auth_reference,
                                auth_memberid: res.data.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                            this.SET_LOGIN_STATUS(true);
                            location.reload()
                        } else if (res.data.status == 404) {
                            this.showDismissibleAlert = true
                            this.$toasted.show("ชื่อผู้ใช้งานหรือรหัสผ่าน ไม่ถูกต้อง", {
                                theme: "bubble",
                                position: "top-center",
                                duration: 5000,
                                type: 'error'
                            });
                        } else if (res.data.status == 403) {
                            this.showDismissibleAlert = true
                            this.$toasted.show("บัญชีของคุณถูกระงับ กรุณาติดต่อเจ้าหน้าที่ที่เพจเฟสบุ๊ก", {
                                theme: "bubble",
                                position: "top-center",
                                duration: 5000,
                                type: 'error'
                            });
                        }
                        this.loadingLogin = false
                    })
                    .catch(err => {
                        console.error("login_error : " + err);
                    });
            } catch (err) {
                console.error("login_error : " + err);
            }
        }
    }
}
</script>

<style scoped>
.signUp, .forgot :hover{
    cursor: pointer;
}

.scope {
    padding: 5% 15% 0.2% 15%;
    text-align: center;
}

label {
    float: left;
    font-size: 12px;
    margin-bottom: 0px;
}

.input {
    margin-top: 15px;
}

.option {
    padding: 0px;
    font-size: 13px;
    margin-top: 8px;
}

/* label.custom-control-label > #text, */
.forgot {
    padding-top: 2px;
}

input {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 6px;
}

.login {
    background: #F5D087;
}

.login:focus,
.login:hover {
    background-color: #FAC762;
    box-shadow: none;
}

.login,
.loginWithFb {
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
    border: none;
    font-weight: bold;
}

.loginWithFb {
    margin-bottom: 20px;
    background-color: #4267B2;
}

.loginWithFb:focus,
.loginWithFb:hover {
    background-color: rgb(42, 83, 163);
    box-shadow: none;
}

.asking {
    font-size: 13px;
}

.signUp,
.forgot {
    color: #EE806B;
    font-weight: bold;
}

.signUp {
    text-decoration: underline;
}
</style>
