<template>
<div class="scope">
    <div class="text-center">
        <img src="./../../assets/logo_novelrealm.png" width="80px" />
    </div>
    <div class="text-center">สมัครสมาชิก</div>
    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
        <div class="input">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
                <label for="email">อีเมล</label>
                <b-form-input id="email" v-model="email" placeholder="abc@mail.com"></b-form-input>
                <span class="errors">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="input">
            <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="displayname">ชื่อที่ใช้ในเว็บ</label>
                <b-form-input id="displayname" v-model="displayname" placeholder="ชื่อที่ใช้ในเว็บ"></b-form-input>
                <span class="errors">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="input">
            <ValidationProvider :rules="{ regex: /^(?=.*[A-Za-z])(?=.\d*)[A-Za-z\d]{6,}$/ , required: true}" v-slot="{ errors }">
                <label for="username">ชื่อผู้ใช้งาน</label>
                <b-form-input id="username" v-model="username" placeholder="ชื่อผู้ใช้งาน"></b-form-input>
                <span class="errors" v-if="errors.length > 0 && errors[0] != 'กรุณากรอกข้อมูล'">ขั้นต่ำ 6 ตัวอักษร และต้องประกอบด้วยตัวอักษรภาษาอังกฤษอย่างน้อย1ตัว และมีหรือไม่มีตัวเลขก็ได้</span>
                <span class="errors" v-else>{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="input">
            <ValidationProvider :rules="{ regex:  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,}$/ , required: true}" v-slot="{ errors }" vid="confirmation">
                <label for="password">รหัสผ่าน</label>
                <b-form-input type="password" id="password" v-model="password" placeholder="รหัสผ่าน"></b-form-input>
                <span class="errors" v-if="errors.length > 0 && errors[0] != 'กรุณากรอกข้อมูล'">ขั้นต่ำ 8 ตัวอักษร และต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่, พิมพ์เล็ก, ตัวเลขและอักขระพิเศษอย่างน้อยชนิดละ 1 ตัว</span>
                <span class="errors" v-else>{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="input">
            <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors }">
                <label for="cfpassword">ยืนยันรหัสผ่าน</label>
                <b-form-input type="password" id="cfpassword" v-model="cfpassword" placeholder="ยืนยันรหัสผ่าน"></b-form-input>
                <span class="errors">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>

        <b-button id="regisBtn" class="login" @click="register()" :disabled="invalid">
            <b-spinner v-if="loadingRegis" small label="Small Spinner" class="mr-2"></b-spinner>สมัครสมาชิก
        </b-button>
    </ValidationObserver>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions
} from 'vuex'

export default {
    data() {
        return {
            loadingRegis: false,
            email: "",
            displayname: "",
            username: "",
            password: "",
            cfpassword: "",
            showDismissibleAlert: false,
            linkToFb: "",
            autoLogin: false
        }
    },
    async mounted() {
        const {
            data
        } = await axios.get('https://api2.novelrealm.com/module/facebook&submodule=generate')
        this.linkToFb = data.redirect_url
    },
    methods: {
        ...mapActions(['SET_LOGIN_STATUS', 'SET_USER_INFO']),
        async register() {
            this.loadingRegis = true
            const formData = new FormData();
            formData.append('mail', this.email);
            formData.append('display_name', this.displayname);
            formData.append('username', this.username);
            formData.append('password', this.password);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/register", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.register == 'success') {
                            this.login(this.username, this.password)
                        } else if (res.data.register == 'fail') {
                            var txt = "";
                            if (res.data.display_name == 'exsited') {
                                txt += "ชื่อผู้ใช้งานซ้ำ "
                            }
                            if (res.data.username == 'exsited') {
                                txt += "ชื่อที่ใช้ในเว็บซ้ำ "
                            }
                            if (res.data.mail == 'exsited') {
                                txt += "อีเมลซ้ำ "
                            }
                            this.$toasted.show(txt, {
                                theme: "bubble",
                                position: "top-center",
                                duration: 5000,
                                type: 'error'
                            });
                        }
                        this.loadingRegis = false
                    })
                    .catch(err => {
                        console.error("login_error : " + err);
                    });
            } catch (err) {
                console.error("login_error : " + err);
            }
        },
        async login(username, password) {
            //console.log(username)
            //console.log(password)
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            //console.log(formData.get('username'))
            //console.log(formData.get('password'))
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/login", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.status == 200) {
                            this.SET_USER_INFO({
                                auth_token: res.data.auth_reference,
                                auth_memberid: res.data.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                            this.SET_LOGIN_STATUS(true);
                            location.reload()
                        }
                    })
                    .catch(err => {
                        console.error("login_error : " + err);
                    });
            } catch (err) {
                console.error("login_error : " + err);
            }
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 5% 15% 0.2% 15%;
}

.errors {
    font-size: 14px;
    color: #EE806B;
}

label {
    float: left;
    font-size: 12px;
    margin-bottom: 0px;
}

.input {
    margin-top: 15px;
}

.option {
    padding: 0px;
    font-size: 13px;
    margin-top: 8px;
}

.forgot {
    padding-top: 2px;
}

input {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 6px;
}

.login {
    background: #F5D087;
}

.login:focus,
.login:hover {
    background-color: #FAC762;
    box-shadow: none;
}

.login,
.loginWithFb {
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
    border: none;
    font-weight: bold;
}

.loginWithFb {
    margin-bottom: 35px;
    background-color: #4267B2;
}

.loginWithFb:focus,
.loginWithFb:hover {
    background-color: rgb(42, 83, 163);
    box-shadow: none;
}
</style>
