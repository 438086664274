var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scope"},[_vm._m(0),_c('div',{staticClass:"text-center"},[_vm._v("สมัครสมาชิก")]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("อีเมล")]),_c('b-form-input',{attrs:{"id":"email","placeholder":"abc@mail.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"displayname"}},[_vm._v("ชื่อที่ใช้ในเว็บ")]),_c('b-form-input',{attrs:{"id":"displayname","placeholder":"ชื่อที่ใช้ในเว็บ"},model:{value:(_vm.displayname),callback:function ($$v) {_vm.displayname=$$v},expression:"displayname"}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":{ regex: /^(?=.*[A-Za-z])(?=.\d*)[A-Za-z\d]{6,}$/ , required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("ชื่อผู้ใช้งาน")]),_c('b-form-input',{attrs:{"id":"username","placeholder":"ชื่อผู้ใช้งาน"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),(errors.length > 0 && errors[0] != 'กรุณากรอกข้อมูล')?_c('span',{staticClass:"errors"},[_vm._v("ขั้นต่ำ 6 ตัวอักษร และต้องประกอบด้วยตัวอักษรภาษาอังกฤษอย่างน้อย1ตัว และมีหรือไม่มีตัวเลขก็ได้")]):_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":{ regex:  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,}$/ , required: true},"vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("รหัสผ่าน")]),_c('b-form-input',{attrs:{"type":"password","id":"password","placeholder":"รหัสผ่าน"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(errors.length > 0 && errors[0] != 'กรุณากรอกข้อมูล')?_c('span',{staticClass:"errors"},[_vm._v("ขั้นต่ำ 8 ตัวอักษร และต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่, พิมพ์เล็ก, ตัวเลขและอักขระพิเศษอย่างน้อยชนิดละ 1 ตัว")]):_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"cfpassword"}},[_vm._v("ยืนยันรหัสผ่าน")]),_c('b-form-input',{attrs:{"type":"password","id":"cfpassword","placeholder":"ยืนยันรหัสผ่าน"},model:{value:(_vm.cfpassword),callback:function ($$v) {_vm.cfpassword=$$v},expression:"cfpassword"}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"login",attrs:{"id":"regisBtn","disabled":invalid},on:{"click":function($event){return _vm.register()}}},[(_vm.loadingRegis)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":"","label":"Small Spinner"}}):_vm._e(),_vm._v("สมัครสมาชิก ")],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("./../../assets/logo_novelrealm.png"),"width":"80px"}})])}]

export { render, staticRenderFns }